import React from "react"
import { Wrapper, TitleSection } from "../index"
import { FormattedMessage } from "react-intl"
// import pin from "../../assets/img/svg/maps-and-flags.svg";
import { Section, Address, Link } from "./styles"
import ScrollAnimate from "react-animate-on-scroll"
import Flag from "react-flagkit"

const ContatoSection = () => (
  <Section id="contato">
    <Wrapper alignItems="center">
      <TitleSection>
        <FormattedMessage id="contact.title" />
      </TitleSection>
      <div className="tel">
        <Link href="tel:+551141134096">+55 11 4113 - 4096</Link>
        <Link href="tel:+5571999663060">+55 71 99966 - 3060</Link>
        <Link href="mailto:contato@asiafacilities.com.br">
          contato@asiafacilities.com.br
        </Link>
      </div>
      <div className="address">
        {/* <ScrollAnimate animateIn="wobble" initiallyVisible>
          <img style={{ marginRight: "20px" }} src={pin} alt="" />
        </ScrollAnimate> */}
        <div>
          <Address>
            <ScrollAnimate animateIn="bounceInRight">
              <strong>Santos - SP - Brasil </strong>{" "}
              <Flag country="BR" size={15} style={{ marginBottom: "-2px" }} />
              <br />
              Rua Guaiaó, 66 - Sala 1902 <br />
              Aparecida - CEP: 11035-260
            </ScrollAnimate>
          </Address>
          <Address>
            <ScrollAnimate animateIn="bounceInRight">
              <strong>Vitória - ES - Brasil </strong>{" "}
              <Flag country="BR" size={15} style={{ marginBottom: "-2px" }} />{" "}
              <br />
              Rua Major Clarindo Fundão, 156 - Ed. London Tower, Sala 302 <br />
              Praia do Canto - CEP: 29055-655
            </ScrollAnimate>
          </Address>
          <Address>
            <ScrollAnimate animateIn="bounceInRight">
              <strong>Bahia - BA - Brasil </strong>{" "}
              <Flag country="BR" size={15} style={{ marginBottom: "-2px" }} />{" "}
              <br />
              Rua Paraná, 29, Edf. Atlântico
              <br /> Empresarial, sala 202 <br />
              Salvador - CEP: 41830-170
            </ScrollAnimate>
          </Address>
          <Address className="fix">
            <ScrollAnimate animateIn="bounceInRight">
              <strong>Rondônia - RO - Brasil </strong>{" "}
              <Flag country="BR" size={15} style={{ marginBottom: "-2px" }} />{" "}
              <br />
              Rua Curimatã 6541,Bairro lagoa <br />
              Porto Velho - CEP: 76812-064
            </ScrollAnimate>
          </Address>
        </div>
        <div>
          <Address>
            <ScrollAnimate animateIn="bounceInRight">
              <strong>Pernanbuco - PE - Brasil </strong>{" "}
              <Flag country="BR" size={15} style={{ marginBottom: "-2px" }} />{" "}
              <br />
              Rua Estado de Israel, 262 - Sala 903 <br />
              Ilha do Leite Recife - CEP: 50070-420
            </ScrollAnimate>
          </Address>

          <Address>
            <ScrollAnimate animateIn="bounceInRight">
              <strong>Qingyuan - China </strong>
              <Flag
                country="CN"
                size={15}
                style={{ marginBottom: "-2px" }}
              />{" "}
              <br />
              RongFa Nan ,Longhua Road,
              <br /> Zhouxin Street, Qingcheng District,
              <br />
              Qingyuan City, Guangdong
            </ScrollAnimate>
          </Address>
          <Address>
            <ScrollAnimate animateIn="bounceInRight">
              <strong>Ningbo - China</strong>{" "}
              <Flag country="CN" size={15} style={{ marginBottom: "-2px" }} />
              <br />
              Room 504.1 Building,
              <br /> Yinchen International NO.668
              <br />
              Jingjia road Ningbo, 315040
            </ScrollAnimate>
          </Address>
          <Address>
            <ScrollAnimate animateIn="bounceInRight">
              <strong>Mumbai - India</strong>{" "}
              <Flag country="IN" size={15} style={{ marginBottom: "-2px" }} />{" "}
              <br />
              334, Kaliyandas Udyogh Bhavan,
              <br /> Prabhadevi, Mumbai 400025{" "}
            </ScrollAnimate>
          </Address>
        </div>
      </div>
    </Wrapper>
  </Section>
)

export default ContatoSection
