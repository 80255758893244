import styled from "styled-components"
import { Field } from "formik"

export const FieldCustom = styled(Field)`
  display: block;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  margin: 0 0 7px 0;
  box-sizing: border-box;
`

export const Button = styled.button`
  background: ${props => props.theme.colors.primary};
  border: none;
  padding: 7px 20px;
  color: #fff;
  font-weight: 700;
  margin-left: auto;
  margin-top: 30px;
  display: block;
  border-radius: 3px;
  cursor: pointer;
  transition: transform 400ms ease;

  &:hover {
    transform: translateY(-3px);
  }
  &:active {
    transform: translateY(-1px);
  }
`

export const H3 = styled.h3`
  font-size: 20px;

  margin-bottom: 10px;
  color: #f0f0f0;
`

export const Error = styled.small`
  display: block;
  color: white;
  transform: translateY(-7px);
`
