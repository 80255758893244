import styled, { keyframes } from "styled-components"

import logo from "../../assets/img/svg/logotipo-letter.svg"
import iconLogo from "../../assets/img/svg/icon-logo.svg"

export const Section = styled.section`
  background: radial-gradient(#f3f9fb, #dfefef);
  padding: 6rem 0 0;
  margin-top: -10px;
`

const rotate = keyframes`
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  `

export const Icon = styled.img.attrs({
  src: iconLogo,
})`
  animation: ${rotate} 12s linear infinite;

  width: 100px;
  height: 110px;
  @media only screen and (min-width: 600px) {
    width: 150px;
    height: 160px;
  }
`

export const Img = styled.img.attrs({
  src: logo,
})`
  margin-left: -30px;
  transform: scale(0.6);
  @media only screen and (min-width: 600px) {
    margin-left: 0;
    transform: scale(1);
  }
`

export const WrapperIcon = styled.div`
  width: 300px;
  grid-column: 2 / span 1;
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 600px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  @media only screen and (min-width: 800px) {
    flex-direction: row;
    width: 400px;
  }
`
