import React from "react"
import { withFormik, Form } from "formik"
import * as Yup from "yup"
import { FieldCustom, Button, Error, H3 } from "../styles"
import { MdSend, MdDirectionsBoat } from "react-icons/md"
import { FormattedMessage } from "react-intl"
import Axios from "axios"

const MyFormWithFormik = withFormik({
  mapPropsToValues: () => ({
    name: "",
    email: "",
    containerType: "",
    merchandise: "",
    origin: "",
    destination: "",
    incoterms: "",
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required()
      .min(5),
    email: Yup.string()
      .email()
      .required(),
    containerType: Yup.string().required(),
    merchandise: Yup.string().required(),
    origin: Yup.string().required(),
    destination: Yup.string().required(),
    incoterms: Yup.string().required(),
  }),
  handleSubmit: async (values, { resetForm }) => {
    console.log({ formulario: "Transporte Maritimo", ...values })
    await Axios.post(
      "http://www.asiafacilities.com.br/send_mail.php",
      JSON.stringify({ formulario: "Transporte Maritimo", ...values }),
      {
        headers: { "content-type": "application/json" },
      }
    )
      .then(function(res) {
        /* console.log(res) */
        resetForm("")
      })
      .catch(function(error) {
        console.log(error)
      })
  },
})

const Formatted = ({ name, id }) => (
  <FormattedMessage id={id}>
    {placeholder => <FieldCustom name={name} placeholder={placeholder} />}
  </FormattedMessage>
)

const CotacaoForm2 = ({ errors, touched }) => (
  <div>
    <H3>
      <MdDirectionsBoat size="22px" />
      <FormattedMessage id="modal.form.title.maritimoTransport" />
    </H3>
    <Form>
      <Formatted id="modal.form.fullName" name="name" />
      {errors.name && touched.name && <Error>{errors.name}</Error>}

      <Formatted id="modal.form.email" name="email" />
      {errors.email && touched.email && <Error>{errors.email}</Error>}

      <Formatted id="modal.form.containerType" name="containerType" />
      {errors.containerType && touched.containerType && (
        <Error>{errors.containerType}</Error>
      )}

      <Formatted id="modal.form.merchandise" name="merchandise" />
      {errors.merchandise && touched.merchandise && (
        <Error>{errors.merchandise}</Error>
      )}

      <Formatted id="modal.form.origin" name="origin" />
      {errors.origin && touched.origin && <Error>{errors.origin}</Error>}

      <Formatted id="modal.form.destination" name="destination" />
      {errors.destination && touched.destination && (
        <Error>{errors.destination}</Error>
      )}

      <Formatted id="modal.form.incoterms" name="incoterms" />
      {errors.incoterms && touched.incoterms && (
        <Error>{errors.incoterms}</Error>
      )}

      <Button type="submit">
        <FormattedMessage id="button.send" />{" "}
        <MdSend size="22px" style={{ marginBottom: "-6px" }} />
      </Button>
    </Form>
  </div>
)

export default MyFormWithFormik(CotacaoForm2)
