import React from "react"
import { FormattedHTMLMessage } from "react-intl"
import { Section, Paragraph } from "./styles"

import { Wrapper, TitleSection } from "../index"

const ConsutoriaGlobalSection = () => (
  <Section>
    <Wrapper>
      <TitleSection dark>
        <FormattedHTMLMessage id="globalSourcing.title" />
      </TitleSection>
      <Paragraph>
        <FormattedHTMLMessage id="globalSourcing.content" />
      </Paragraph>
    </Wrapper>
  </Section>
)

export default ConsutoriaGlobalSection
