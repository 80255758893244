import React from "react"
import styled from "styled-components"
import { FormattedHTMLMessage } from "react-intl"

import { MdClose } from "react-icons/md"

const Wrapper = styled.div`
  position: relative;
`

const H2 = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  text-transform: capitalize;
  font-size: 22px;
`

const Content = styled.p`
  font-size: 18px;
  color: #fff;
  text-indent: 40px;
`
const Close = styled.div`
  color: red;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`

const ServiceModal = ({ title, content, toogleModal }) => (
  <Wrapper>
    {title && (
      <H2>
        <FormattedHTMLMessage id={title} />
      </H2>
    )}
    <Content>
      <FormattedHTMLMessage id={content} />
    </Content>
    <Close title="fechar">
      <MdClose
        size="22px"
        style={{ marginBottom: "-6px" }}
        onClick={toogleModal}
      />
    </Close>
  </Wrapper>
)

export default ServiceModal
