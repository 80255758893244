import React from "react"
import { Section, List, Item, Title } from "./styles"
import { TitleSection, Wrapper, Button } from "../index"
import ScrollAnimattion from "react-animate-on-scroll"
import { FormattedMessage, FormattedHTMLMessage } from "react-intl"

import { MdChevronRight } from "react-icons/md"

const ServicesSection = ({ toogleModal, updateModal }) => (
  <Section id="servicos">
    <div style={{ maxWidth: "900px", margin: "auto" }}>
      <TitleSection center>
        <FormattedMessage id="ourServices.title" />
      </TitleSection>
      <p style={{ color: "#595B61", marginTop: "-15px" }}>
        <FormattedMessage id="ourServices.subtitle" />
      </p>
    </div>
    <Wrapper
      style={{ marginLeft: "auto", marginRight: "auto", overflow: "hidden" }}
    >
      <List>
        <ScrollAnimattion
          style={{ width: "180px" }}
          animateIn="bounceInRight"
          animateOut="bounceOutLeft"
        >
          <Item>
            <img src={require("../../assets/img/svg/woldwide.svg")} alt="" />
            <Title>
              <FormattedHTMLMessage id="ourServices.logistic.home.title" />
            </Title>
            <Button
              onClick={event => {
                toogleModal(event)
                updateModal("ourServices.logistic")
              }}
            >
              <FormattedMessage id="button.moreInfo" />{" "}
              <MdChevronRight size="22px" style={{ marginBottom: "-6px" }} />
            </Button>
          </Item>
        </ScrollAnimattion>
        <ScrollAnimattion
          style={{ width: "180px" }}
          animateIn="bounceInRight"
          animateOut="bounceOutLeft"
        >
          <Item>
            <img src={require("../../assets/img/svg/avatar.svg")} alt="" />
            <Title>
              <FormattedHTMLMessage id="ourServices.consulting.home.title" />
            </Title>
            <Button
              onClick={event => {
                toogleModal(event)
                updateModal("ourServices.consulting")
              }}
            >
              <FormattedMessage id="button.moreInfo" />
              <MdChevronRight
                size="22px"
                style={{ marginBottom: "-6px" }}
              />{" "}
            </Button>
          </Item>
        </ScrollAnimattion>
        <ScrollAnimattion
          style={{ width: "180px" }}
          animateIn="bounceInRight"
          animateOut="bounceOutLeft"
        >
          <Item>
            <img
              src={require("../../assets/img/svg/male-consultant.svg")}
              alt=""
            />
            <Title>
              <FormattedHTMLMessage id="ourServices.assistence.home.title" />
            </Title>
            <Button
              onClick={event => {
                toogleModal(event)
                updateModal("ourServices.assistence")
              }}
            >
              <FormattedMessage id="button.moreInfo" />
              <MdChevronRight
                size="22px"
                style={{ marginBottom: "-6px" }}
              />{" "}
            </Button>
          </Item>
        </ScrollAnimattion>
        <ScrollAnimattion
          style={{ width: "180px" }}
          animateIn="bounceInRight"
          animateOut="bounceOutLeft"
        >
          <Item>
            <img src={require("../../assets/img/svg/transport.svg")} alt="" />
            <Title>
              <FormattedHTMLMessage id="ourServices.import.home.title" />
            </Title>
            <Button
              onClick={event => {
                toogleModal(event)
                updateModal("ourServices.import")
              }}
            >
              <FormattedMessage id="button.moreInfo" />
              <MdChevronRight
                size="22px"
                style={{ marginBottom: "-6px" }}
              />{" "}
            </Button>
          </Item>
        </ScrollAnimattion>
        <ScrollAnimattion
          style={{ width: "180px" }}
          animateIn="bounceInRight"
          animateOut="bounceOutLeft"
        >
          <Item>
            <img src={require("../../assets/img/svg/certificate.svg")} alt="" />
            <Title>
              <FormattedHTMLMessage id="ourServices.certifications.home.title" />
            </Title>
            <Button
              onClick={event => {
                toogleModal(event)
                updateModal("ourServices.certifications")
              }}
            >
              <FormattedMessage id="button.moreInfo" />
              <MdChevronRight
                size="22px"
                style={{ marginBottom: "-6px" }}
              />{" "}
            </Button>
          </Item>
        </ScrollAnimattion>
      </List>
    </Wrapper>
  </Section>
)

export default ServicesSection
