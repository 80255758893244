import styled from "styled-components"

export const Section = styled.section`
  background-image: url(${require("../../assets/img/bg-service.png")});
  background-position: center center;
  background-size: 1200;

  padding: 6rem 1rem;
`

export const List = styled.ul`
  display: flex;

  justify-content: space-between;
  align-items: flex-end;
  grid-column: 2 / 4;
  width: 100%;

  margin: 70px auto 20px auto;

  @media only screen and (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
`

export const Item = styled.li`
  margin: 0 10px;
  width: 180px;
  text-align: center;
  list-style: none;

  @media only screen and (max-width: 900px) {
    margin: 0;
    margin-top: 70px;
  }

  img {
    display: block;
    margin: 0 auto 20px;
  }
`

export const Title = styled.h3`
  span {
    font-size: 22px;
    color: ${props => props.theme.colors.primary};
    text-align: center;
    span {
      display: inline-block;
      font-size: 12px;
      color: ${props => props.theme.colors.title.gray};
    }
  }
`
