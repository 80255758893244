import React, { Fragment } from "react"
import CarouselReact from "nuka-carousel"
import { Button } from "../index"
import { Wrapper, Content, Paragraph } from "./styles"
import MediaQuery from "react-responsive"
import bg from "../../assets/img/banner2.png"
import { MdAccountCircle, MdArrowBack, MdArrowForward } from "react-icons/md"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { FormattedMessage } from "react-intl"

const Slider = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "boats-cargo-cargo-container.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1255) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      hero2: file(relativePath: { eq: "hero-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1255) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <CarouselReact
      wrapAround
      autoplay
      autoplayInterval={10000}
      pauseOnHover
      renderCenterLeftControls={({ previousSlide }) => (
        <button
          onClick={previousSlide}
          style={{
            background: "transparent",
            border: "none",
            cursor: "pointer",
          }}
        >
          <MdArrowBack size="2em" color="#55C5D0" />
        </button>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <button
          onClick={nextSlide}
          style={{
            background: "transparent",
            border: "none",
            cursor: "pointer",
          }}
        >
          <MdArrowForward size="2em" color="#55C5D0" />
        </button>
      )}
    >
      <Wrapper>
        <Image fluid={data.hero.childImageSharp.fluid} />
        <Content>
          <Paragraph>
            <FormattedMessage id="carousel.content" />
          </Paragraph>
          <Button as="a" href="#sobre-nos" style={{ padding: "10px 17px" }}>
            <MdAccountCircle /> <FormattedMessage id="button.aboutUs" />
          </Button>
        </Content>
      </Wrapper>

      <Wrapper>
        <Image fluid={data.hero2.childImageSharp.fluid} />
        <Content>
          <Paragraph>
            <FormattedMessage id="carousel.content" />
          </Paragraph>

          <Button as="a" href="#sobre-nos" style={{ padding: "10px 17px" }}>
            <MdAccountCircle /> <FormattedMessage id="button.aboutUs" />
          </Button>
        </Content>
      </Wrapper>
    </CarouselReact>
  )
}

const Mobile = () => (
  <Wrapper
    style={{
      height: "calc(100vh - 90px)",
      width: "100%",
      backgroundImage: `url(${bg})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}
  >
    <Content>
      <Paragraph style={{ fontSize: "16px" }}>
        <FormattedMessage id="carousel.content" />
      </Paragraph>

      <Button as="a" href="#sobre-nos" style={{ padding: "10px 17px" }}>
        <MdAccountCircle /> <FormattedMessage id="button.aboutUs" />
      </Button>
    </Content>
  </Wrapper>
)

const Carousel = () => (
  <Fragment>
    <MediaQuery minWidth={600}>
      <Slider />
    </MediaQuery>
    <MediaQuery maxWidth={599}>
      <Mobile />
    </MediaQuery>
  </Fragment>
)

export default Carousel
