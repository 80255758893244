import React from "react"
import styled from "styled-components"

const Button = styled.button`
  position: fixed;
  width: 50px;
  height: 50px;
  padding: 5px;
  border-radius: 50%;
  top: 25px;
  right: 15px;
  background: #55c5d0;
  border: none;
  padding: 0;
  z-index: 400;
  box-shadow: -4px 3px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

const Toggle = styled.span`
  position: absolute;
  width: 30px;
  height: 3px;
  background: ${({ toggle }) => (toggle === "toggle" ? "transparent" : "#fff")};
  transform: translate(-50%, -50%);
  transition: background 200ms linear;
  border-radius: 2px;
  border: none;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 2px;
    border: none;
    background: #fff;
    top: ${({ toggle }) => (toggle === "toggle" ? "0" : "-10px")};
    transition: all 300ms linear;
    transform: ${({ toggle }) => (toggle === "toggle" ? "rotate(135deg)" : "")};
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 2px;
    border: none;
    background: #fff;
    bottom: ${({ toggle }) => (toggle === "toggle" ? "0" : "-10px")};
    transition: all 300ms linear;
    transform: ${({ toggle }) =>
      toggle === "toggle" ? "rotate(-135deg)" : ""};
  }
`

const ToggleButton = ({ isOpen, toggle }) => {
  return (
    <Button onClick={isOpen}>
      <Toggle toggle={toggle}>&nbsp;</Toggle>
    </Button>
  )
}

export default ToggleButton
