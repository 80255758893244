import React, { Component } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import {
  Layout,
  Carousel,
  Topbar,
  SobreSection,
  ServicesSection,
  ConsultorialGlobalSection,
  ContatoSection,
  Loading,
} from "../components"

import AirTransportForm from "../components/Forms/airTransport"
import MaritimoTransportForm from "../components/Forms/maritimoTransport"
import ContactUsForm from "../components/Forms/contactUs"

import Modal from "react-modal"
import {
  customStyles,
  customStylesCotacao,
  customStylesForm,
} from "../../config/stylesModal"

import ServiceModal from "../components/Modal/serviceModal"
import { MdPersonPin, MdDirectionsBoat, MdFlightTakeoff } from "react-icons/md"
import { FormattedMessage } from "react-intl"
import SectionInstagram from "../components/sectionInstagram"

Modal.setAppElement("#___gatsby")
Modal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0.9)"

const Button = styled.button`
  padding: 7px 20px;
  border: none;
  color: #595b61;
  font-weight: 700;
  border-radius: 3px;
  margin: 2.5px;
  background-color: rgba(255, 255, 255, 0.8);
  transition: all 400ms;

  :hover {
    background-color: #55c5d0;
    color: #fff;
  }
`

class Index extends Component {
  state = {
    loading: true,
    isOpen: false,
    isOpenCotacao: false,
    isOpenform1: false,
    isOpenform2: false,
    isOpenform3: false,
    title: "",
    content: "",
  }

  componentDidMount = () => {
    const { loading } = this.state
    setTimeout(() => {
      this.setState({ loading: !loading })
    }, 5000)
  }

  toogleModal = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  toogleModalCotacao = event => {
    event.preventDefault()
    this.setState({ isOpenCotacao: !this.state.isOpenCotacao })
  }

  toogleModalForm1 = event => {
    event.preventDefault()
    this.setState({ isOpenform1: !this.state.isOpenform1 })
  }
  toogleModalForm2 = event => {
    event.preventDefault()
    this.setState({ isOpenform2: !this.state.isOpenform2 })
  }
  toogleModalForm3 = event => {
    event.preventDefault()
    this.setState({ isOpenform3: !this.state.isOpenform3 })
  }

  updateModal = item => {
    item === "about"
      ? this.setState({
          title: `${item}.title`,
          content: `${item}.content.full`,
        })
      : this.setState({
          title: `${item}.title`,
          content: `${item}.content`,
        })
  }

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#f00"
  }

  render() {
    const {
      pageContext: { locale },
      data: {
        allInstaNode: { edges },
      },
    } = this.props

    return (
      <Layout locale={locale}>
        {this.state.loading && <Loading />}
        <Topbar toogleModalCotacao={this.toogleModalCotacao} />
        <Carousel toogleModalCotacao={this.toogleModalCotacao} />
        <SobreSection
          toogleModal={this.toogleModal}
          updateModal={this.updateModal}
        />

        <SectionInstagram nodes={edges} />

        <ServicesSection
          toogleModal={this.toogleModal}
          updateModal={this.updateModal}
        />

        <ConsultorialGlobalSection />
        <ContatoSection />

        <Modal
          isOpen={this.state.isOpen}
          onRequestClose={this.toogleModal}
          style={customStyles}
          contentLabel="Service Modal"
        >
          <ServiceModal
            toogleModal={this.toogleModal}
            title={this.state.title}
            content={this.state.content}
          />
        </Modal>

        <Modal
          isOpen={this.state.isOpenCotacao}
          onRequestClose={this.toogleModalCotacao}
          style={customStylesCotacao}
          contentLabel="Cotacao Modal"
        >
          <div>
            <Button onClick={this.toogleModalForm1}>
              <MdFlightTakeoff size="22px" style={{ marginBottom: "-6px" }} />{" "}
              <FormattedMessage id="modal.form.title.airTransport" />
            </Button>
            <Button onClick={this.toogleModalForm2}>
              {" "}
              <MdDirectionsBoat
                size="22px"
                style={{ marginBottom: "-6px" }}
              />{" "}
              <FormattedMessage id="modal.form.title.maritimoTransport" />
            </Button>
            <Button onClick={this.toogleModalForm3}>
              <MdPersonPin size="22px" style={{ marginBottom: "-6px" }} />
              <FormattedMessage id="modal.form.title.contactUs" />
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.isOpenform1}
          onRequestClose={this.toogleModalForm1}
          style={customStylesForm}
          contentLabel="Cotacao Modal"
        >
          <AirTransportForm close={this.toogleModalForm1} />
        </Modal>

        <Modal
          isOpen={this.state.isOpenform2}
          onRequestClose={this.toogleModalForm2}
          style={customStylesForm}
          contentLabel="Cotacao Modal"
        >
          <MaritimoTransportForm close={this.toogleModalForm2} />
        </Modal>

        <Modal
          isOpen={this.state.isOpenform3}
          onRequestClose={this.toogleModalForm3}
          style={customStylesForm}
          contentLabel="Cotacao Modal"
        >
          <ContactUsForm close={this.toogleModalForm3} />
        </Modal>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allInstaNode {
      edges {
        node {
          id
          username
          likes
          comments
          caption
          localFile {
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 300) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default Index
