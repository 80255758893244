const colors = {
  primary: "#55C5D0",
  backgroundDark: "#595B61",
  title: {
    dark: "#595B61",
    light: "#fff",
    gray: "#A7A9AC"
  }
};

const transition = {
  linear: "all 400ms linear"
};

const theme = {
  colors,
  transition
};

export default theme;
