import styled from "styled-components"

const Button = styled.button`
  display: inline-block;

  padding: 10px 12px;
  background: ${props =>
    props.ghost ? "transparente" : "linear-gradient(#38c6bc, #438a85)"};
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  color: ${({ theme }) => theme.colors.title.light};
  border: none;
  ${props => props.ghost && "box-shadow: 0px 0px 0px 2px white inset"};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : "")};
  ${props =>
    props.left &&
    `
    right: 0;
    position: absolute;
  `}

  transition: all 300ms linear;
  user-select: none;

  text-decoration: none;
  font-weight: 700;
  text-align: center;
  cursor: pointer;

  :hover {
    transform: translateY(-3px);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    ${props => props.ghost && "box-shadow: 0px 0px 0px 2px white inset"};
  }

  :active {
    transform: translateY(-1px);
    box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.25);
    ${props => props.ghost && "box-shadow: 0px 0px 0px 2px white inset"};
  }
`

export default Button
