import styled from "styled-components";

import mapAsia from "../../assets/img/svg/mapAsia.svg";

export const Section = styled.section`
  background: #595B61 url(${mapAsia}) no-repeat center center / 80% 80%;
  padding: 6rem 1rem;
  color: #fff;
`;

export const Paragraph = styled.p`
  grid-column: 2 / span 2;

  @media screen and (min-width: 600px) {
    column-count: 2;
    margin-bottom: 10px;
  }
`;
