import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import Carousel from "nuka-carousel"
import { FaRegHeart, FaRegCommentDots } from "react-icons/fa"

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  cursor: pointer;
  z-index: 100;
  transition: all 600ms ease;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
`
const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Info = styled.span`
  display: block;
  margin: 0 10px;
  color: white;
  z-index: 200;
  opacity: 0;
  transition: all 600ms ease;
`
const Wrapper = styled.a`
  position: relative;
  max-height: 300px;
  max-width: 300px;
  display: block;

  &:hover {
    ${Overlay} {
      opacity: 1;
    }
    ${Info} {
      opacity: 1;
    }
  }
`

const Instagram = ({ nodes }) => {
  const [slidesShow, setSlidesShow] = useState(initalWidth())

  function initalWidth() {
    const windowGlobal = typeof window !== "undefined" && window
    return windowGlobal.innerWidth > 600 ? 4 : 2
  }

  useEffect(() => {
    const handleResize = () => setSlidesShow(window.innerWidth > 600 ? 4 : 2)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  return (
    <Carousel
      slidesToShow={slidesShow}
      cellSpacing={10}
      withoutControls={true}
      wrapAround
      autoplay
      pauseOnHover
      dragging
      cellAlign="center"
      autoGenerateStyleTag={false}
    >
      {nodes.map(post => (
        <Wrapper
          key={post.node.id}
          href={`https://www.instagram.com/p/${post.node.id}/`}
          title={post.node.caption}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Overlay />
          <Img fluid={post.node.localFile.childImageSharp.fluid} />
          <Content>
            <Info>
              <FaRegHeart /> <span>{post.node.likes}</span>
            </Info>
            <Info>
              <FaRegCommentDots />
              {"  "} <span>{post.node.comments || 0}</span>
            </Info>
          </Content>
        </Wrapper>
      ))}
    </Carousel>
  )
}

export default Instagram
