import React from "react"
import styled, { keyframes } from "styled-components"
import logoLetter from "../../assets/img/svg/logotipo-letter.svg"
import iconLogo from "../../assets/img/svg/icon-logo.svg"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1000;

  background: #cfcfcf;
`
const WrapperImg = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`

const Img = styled.img`
  transform: scale(0.7);
  @media only screen and (min-width: 600px) {
    transform: scale(1);
  }
`

const rotate = keyframes`
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  `

const Icon = styled(Img)`
  animation: ${rotate} 4s linear infinite;
  transform: scale(0.7);
`
const Images = styled(Image)`
  width: 100%;

  @media only screen and (max-width: 600px) {
    width: 1000px;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
`

const Loading = () => {
  const data = useStaticQuery(graphql`
    query {
      bgLoading: file(relativePath: { eq: "bg-loading.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Background>
      <Images fluid={data.bgLoading.childImageSharp.fluid} />
      <WrapperImg>
        <Icon src={iconLogo} />
        <Img src={logoLetter} alt="logotipo asia logistic" />
      </WrapperImg>
    </Background>
  )
}

export default Loading
