import styled from "styled-components"
import mapa from "../../assets/img/mapa.png"

export const Section = styled.section`
  padding: 6rem 1rem;
  overflow-x: hidden;
  background: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.8)
    ),
    url(${mapa}) top center / cover;

  .tel {
    margin-bottom: 30px;
    text-align: center;
    color: #55c5d0;

    @media screen and (min-width: 600px) {
      text-align: left;
    }
  }

  .address {
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    margin: auto;
    justify-content: center;

    @media screen and (min-width: 600px) {
      flex-direction: row;
    }
  }
`

export const Address = styled.address`
  position: relative;
  color: black;

  margin-bottom: 0;
  border-left: 3px solid ${props => props.theme.colors.primary};
  padding: 10px;
  color: #595b61;
  justify-items: center;

  margin-left: auto;
  margin-right: auto;

  width: 280px;

  :not(:last-child) {
    ::after {
      content: "";
      display: block;
      position: absolute;
      height: 3px;
      width: 70%;
      background-color: ${props => props.theme.colors.primary};
      bottom: -10px;
      left: -15px;
    }
  }
`

export const Link = styled.a`
  display: block;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  line-height: 25px;
  font-size: 24px;
  margin-bottom: 10px;
  color: #595b61;

  text-decoration: none;
`
