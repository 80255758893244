import React from "react"
import { TitleSection } from "../index"
import Instagram from "../sourceInstagram"

const SectionInstagram = ({ nodes }) => {
  return (
    <section style={{ padding: "40px 0", backgroundColor: "#595B61" }}>
      <div style={{ maxWidth: "900px", margin: "auto", padding: "0 1rem" }}>
        <TitleSection dark> Instagram </TitleSection>
      </div>
      <Instagram nodes={nodes} />
    </section>
  )
}

export default SectionInstagram
