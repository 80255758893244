import styled from "styled-components"
import logotipo from "../../assets/img/svg/logo-asiaLogistic.svg"

export const Wrapper = styled.div`
  justify-content: center;
  padding: 0 20px;
  max-width: 900px;
  height: 100px;
  text-align: center;
  margin: auto;

  @media only screen and (min-width: 600px) {
    display: flex;
    justify-content: space-between;
    height: 90px;
  }
`

export const List = styled.ul`
  margin: 0 0 0 auto;
  list-style: none;
  align-items: center;
  z-index: 1;

  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  height: 100vh;
  background: ${props => props.theme.colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ toggle }) => !toggle && "transform: translateY(-100%)"};
  transition: 400ms ease;

  @media only screen and (min-width: 600px) {
    position: relative;
    flex-direction: row;
    height: 100%;
    background: transparent;
    transform: translateY(0);
    justify-content: flex-end;
    align-items: center;
    padding-top: 20px;
  }
`

export const Item = styled.li`
  @media only screen and (min-width: 599px) {
    display: block;
    :not(:first-child) {
      padding-left: 10px;
    }
  }

  img {
    margin: 0;
    width: 26px;
    height: 26px;
    cursor: pointer;
  }

  > ul {
    margin: 0;
    transform: ${({ open }) => (open ? "scaleY(1)" : " scaleY(0)")};
    position: absolute;
    top: 70px;
    list-style: none;
    transition: all 400ms ease;
    transform-origin: 0 0;

    li {
      transition: all 400ms ease;
      margin: 0;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
`

export const Img = styled.img.attrs({
  src: logotipo,
  alt: "Logotipo",
})`
  width: 170px;

  @media only screen and (min-width: 600px) {
    width: 130px;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 599px) {
    margin-top: 15px;
  }
`

export const Linka = styled.a`
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;

  @media only screen and (min-width: 600px) {
    color: #595b61;
    font-size: 16px;
  }

  position: relative;
  transform-origin: left center;
  transition: ${props => props.theme.transition.linear};

  ::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: transparent;

    transform-origin: left center;
    transition: ${props => props.theme.transition.linear};
  }

  :hover,
  :active {
    color: ${({ toggle }) =>
      toggle ? "#595b61" : props => props.theme.colors.primary};

    ::after {
      background-color: ${props => props.theme.colors.primary};

      @media only screen and (max-width: 600px) {
        background-color: #595b61;
      }
    }
  }
`
