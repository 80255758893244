import React from "react"
import { withFormik, Form } from "formik"
import * as Yup from "yup"
import { FieldCustom, Button, Error, H3 } from "../styles"
import { MdSend, MdFlightTakeoff } from "react-icons/md"
import { FormattedMessage } from "react-intl"
import Axios from "axios"

const MyFormWithFormik = withFormik({
  mapPropsToValues: () => ({
    name: "",
    email: "",
    dimensions: "",
    quantity: "",
    merchandise: "",
    origin: "",
    destination: "",
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required()
      .min(5),
    email: Yup.string()
      .email()
      .required(),
    dimensions: Yup.string().required(),
    quantity: Yup.string().required(),
    merchandise: Yup.string().required(),
    origin: Yup.string().required(),
    destination: Yup.string().required(),
  }),
  handleSubmit: async (values, { resetForm }) => {
    await Axios.post(
      "http://www.asiafacilities.com.br/send_mail.php",
      JSON.stringify({ formulario: "Transporte Aéreo", ...values }),
      {
        headers: { "content-type": "application/json" },
      }
    )
      .then(function(res) {
        /* console.log(res) */

        resetForm("")
      })
      .catch(function(error) {
        console.log(error)
      })
  },
})

const Formatted = ({ name, id, style }) => (
  <FormattedMessage id={id}>
    {placeholder => (
      <FieldCustom name={name} placeholder={placeholder} style={{ ...style }} />
    )}
  </FormattedMessage>
)

const CotacaoForm1 = ({ errors, touched, close }) => (
  <div>
    <H3>
      <MdFlightTakeoff size="22px" />{" "}
      <FormattedMessage id="modal.form.title.airTransport" />
    </H3>
    <Form>
      <Formatted id="modal.form.fullName" name="name" />
      {errors.name && touched.name && <Error>{errors.name}</Error>}

      <Formatted id="modal.form.email" name="email" />
      {errors.email && touched.email && <Error>{errors.email}</Error>}

      <div>
        <div style={{ display: "flex" }}>
          <Formatted
            id="modal.form.dimensions"
            name="dimensions"
            style={{ marginRight: "10px" }}
          />
          <Formatted id="modal.form.quantity" name="quantity" />
        </div>

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {errors.dimensions && touched.dimensions && (
            <Error>{errors.dimensions}</Error>
          )}
          {errors.quantity && touched.quantity && (
            <Error>{errors.quantity}</Error>
          )}
        </div>
      </div>

      <Formatted id="modal.form.merchandise" name="merchandise" />
      {errors.merchandise && touched.merchandise && (
        <Error>{errors.merchandise}</Error>
      )}

      <Formatted id="modal.form.origin" name="origin" />
      {errors.origin && touched.origin && <Error>{errors.origin}</Error>}

      <Formatted id="modal.form.destination" name="destination" />
      {errors.destination && touched.destination && (
        <Error>{errors.destination}</Error>
      )}

      <Button type="submit">
        <FormattedMessage id="button.send" />{" "}
        <MdSend size="22px" style={{ marginBottom: "-6px" }} />
      </Button>
    </Form>
  </div>
)

export default MyFormWithFormik(CotacaoForm1)
