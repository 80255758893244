import asiaMap from "../src/assets/img/svg/mapAsia.svg";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: `#595B61`,
    backgroundImage: `url(${asiaMap})`,
    backgroundPosition: "center center",
    backgroundSize: "80% 80%",
    backgroundRepeat: "no-repeat",
    maxWidth: "80vw",
    width: "100%",
    maxHeight: "90%",
    zIndex: 1000
  }
};

export const customStylesCotacao = {
  content: {
    top: "20%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: `#474f85`,
    backgroundImage: `url(${asiaMap})`,
    backgroundPosition: "center center",
    backgroundSize: "80% 80%",
    backgroundRepeat: "no-repeat",
    maxHeight: "90%",
    textAlign: "center"
  }
};

export const customStylesForm = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    border: "none",
    transform: "translate(-50%, -50%)",
    backgroundColor: `#474f85`,
    backgroundImage: `url(${asiaMap})`,
    backgroundPosition: "center center",
    backgroundSize: "80% 80%",
    backgroundRepeat: "no-repeat",
    maxHeight: "90%",
    zIndex: 1000,
    width: "80%",
    maxWidth: "80%"
  }
};
