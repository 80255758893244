import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const WrapperMobile = styled(Wrapper)``;

export const Content = styled.div`
  max-width: 600px;
  position: absolute;
  top: 50%;
  left: 10%;
  right: 10%;
  transform: translateY(-50%);
`;

export const Paragraph = styled.p`
  text-shadow: 1px 2px 7px rgba(0, 0, 0, 0.5);
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #fff;

  @media only screen and (max-width: 899px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 799px) {
    font-size: 16px;
  }
`;

export const Img = styled.img`
  width: 100%;
  padding: 0;
  margin: 0;
`;
