import React, { Fragment } from "react"
import { ThemeProvider } from "styled-components"
import { IntlProvider, addLocaleData } from "react-intl"

import theme from "../../config/theme"
import GlobalStyle from "../../config/globalStyle"

//Lacate date
import brData from "react-intl/locale-data/br"
import enData from "react-intl/locale-data/en"
import zhData from "react-intl/locale-data/zh"

// Messages
import br from "../i18n/br.json"
import en from "../i18n/en.json"
import zh from "../i18n/zh.json"

const messages = { br, en, zh }

addLocaleData([...brData, ...enData, ...zhData])

const Layout = ({ locale, children }) => (
  <ThemeProvider theme={theme}>
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Fragment>
        <GlobalStyle />
        {children}
      </Fragment>
    </IntlProvider>
  </ThemeProvider>
)

export default Layout
