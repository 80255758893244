import React from "react"
import styled from "styled-components"

import { MdChevronRight } from "react-icons/md"
import { FormattedMessage } from "react-intl"

import { Section, Img, Icon, WrapperIcon } from "./styles"
import { Wrapper, TitleSection, Button } from "../index"

const WrapperS = styled(Wrapper)`
  padding: 1rem 1rem 5rem;
  @media only screen and (min-width: 800px) {
    display: flex;
    justify-content: space-between;

    > div {
      max-width: 400px;
    }
  }
`

const SobreSection = ({ updateModal, toogleModal }) => (
  <Section id="sobre-nos">
    <WrapperS>
      <div>
        <TitleSection>
          <FormattedMessage id="about.title" />
        </TitleSection>
        <WrapperIcon>
          <Icon />
          <Img />
        </WrapperIcon>
      </div>
      <div style={{ position: "relative" }}>
        <p style={{ color: "#595B61" }}>
          <FormattedMessage id="about.content.small" />
        </p>
        <Button
          left
          onClick={() => {
            toogleModal()
            updateModal("about")
          }}
        >
          <FormattedMessage id="button.moreInfo" />{" "}
          <MdChevronRight size="22px" style={{ marginBottom: "-6px" }} />
        </Button>
      </div>
    </WrapperS>
  </Section>
)

export default SobreSection
