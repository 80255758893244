import React from "react"
import { withFormik, Form } from "formik"
import * as Yup from "yup"
import { MdSend, MdPersonPin } from "react-icons/md"

import { FieldCustom, Button, Error, H3 } from "../styles"
import { FormattedMessage } from "react-intl"

import axios from "axios"

const MyFormWithFormik = withFormik({
  mapPropsToValues: () => ({
    name: "",
    email: "",
    telephone: "",
    message: "",
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required()
      .min(5),
    email: Yup.string()
      .email()
      .required(),
    telephone: Yup.string().required(),
    message: Yup.string().required(),
  }),
  handleSubmit: async (values, { resetForm }) => {
    await axios
      .post(
        "http://www.asiafacilities.com.br/send_mail.php",
        JSON.stringify({ formulario: "Fale Conosco", ...values }),
        {
          headers: { "content-type": "application/json" },
        }
      )
      .then(function(res) {
       /*  console.log(res) */
        resetForm("")
      })
      .catch(function(error) {
        console.log(error)
      })
  },
})

const Formatted = ({ name, id, component }) => (
  <FormattedMessage id={id}>
    {placeholder => (
      <FieldCustom
        name={name}
        placeholder={placeholder}
        component={component}
      />
    )}
  </FormattedMessage>
)

const CotacaoForm3 = ({ errors, touched }) => (
  <div>
    <H3>
      <MdPersonPin size="22px" />
      <FormattedMessage id="modal.form.title.contactUs" />
    </H3>

    <Form>
      <Formatted id="modal.form.fullName" name="name" />
      {errors.name && touched.name && <Error>{errors.name}</Error>}

      <Formatted id="modal.form.email" name="email" />
      {errors.email && touched.email && <Error>{errors.email}</Error>}

      <Formatted id="modal.form.telephone" name="telephone" />
      {errors.telephone && touched.telephone && (
        <Error>{errors.telephone}</Error>
      )}

      <Formatted id="modal.form.message" name="message" component="textarea" />
      {errors.message && touched.message && <Error>{errors.message}</Error>}

      <Button type="submit">
        <FormattedMessage id="button.send" />{" "}
        <MdSend size="22px" style={{ marginBottom: "-6px" }} />
      </Button>
    </Form>
  </div>
)

export default MyFormWithFormik(CotacaoForm3)
