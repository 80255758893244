import React, { Component } from "react"
import MediaQuery from "react-responsive"
import { navigate } from "@reach/router"
import { List, Linka, Item, Img, Wrapper } from "./styles"
import { ToogleButton } from "../index"
import { FormattedMessage } from "react-intl"

class Topbar extends Component {
  state = {
    isOpen: false,
    lang: "pt",
    open: false,
  }

  handleToogle = () => {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }

  handleLang = (event, location) => {
    event.preventDefault()
    navigate(location)
  }

  openTranslate = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }

  render() {
    const { toogleModalCotacao } = this.props
    const { isOpen } = this.state

    return (
      <nav>
        <Wrapper>
          <Img />
          <MediaQuery query="(max-width: 599px)">
            <ToogleButton
              toggle={isOpen ? "toggle" : ""}
              isOpen={this.handleToogle}
            />
          </MediaQuery>

          <List toggle={isOpen ? "toggle" : ""}>
            <Item>
              <Linka
                toggle={isOpen ? "toggle" : ""}
                onClick={this.handleToogle}
                href="#sobre-nos"
              >
                <FormattedMessage id="navegation.aboutUs" />
              </Linka>
            </Item>
            <Item>
              <Linka
                toggle={isOpen ? "toggle" : ""}
                onClick={this.handleToogle}
                href="#servicos"
              >
                <FormattedMessage id="navegation.services" />
              </Linka>
            </Item>
            <Item>
              <Linka
                toggle={isOpen ? "toggle" : ""}
                onClick={this.handleToogle}
                href="#contato"
              >
                <FormattedMessage id="navegation.contact" />
              </Linka>
            </Item>
            <Item>
              <Linka
                href="#modal"
                toggle={isOpen ? "toggle" : ""}
                onClick={event => {
                  toogleModalCotacao(event)
                  this.handleToogle()
                }}
              >
                <FormattedMessage id="navegation.quote" />
              </Linka>
            </Item>
            <Item open={this.state.open} onClick={this.openTranslate}>
              <img
                src={require("../../assets/img/svg/translate.svg")}
                alt="translate"
                title="Translate"
              />
              <ul>
                <li>
                  <img
                    src={require("../../assets/img/svg/united-states.svg")}
                    alt="United States"
                    title="Ingles"
                    toggle={isOpen ? "toggle" : ""}
                    onClick={e => {
                      this.handleToogle(e)
                      this.handleLang(e, "/en")
                    }}
                  />
                </li>
                <li>
                  <img
                    src={require("../../assets/img/svg/brazil.svg")}
                    alt="Brazil"
                    title="Português"
                    toggle={isOpen ? "toggle" : ""}
                    onClick={e => {
                      this.handleToogle(e)
                      this.handleLang(e, "/")
                    }}
                  />
                </li>
                <li>
                  <img
                    src={require("../../assets/img/svg/china.svg")}
                    alt="China"
                    title="中文"
                    toggle={isOpen ? "toggle" : ""}
                    onClick={e => {
                      this.handleToogle(e)
                      this.handleLang(e, "/zh")
                    }}
                  />
                </li>
              </ul>
            </Item>
          </List>
        </Wrapper>
      </nav>
    )
  }
}

export default Topbar
