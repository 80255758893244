import { createGlobalStyle } from "styled-components"
import animate from "animate.css/animate.min.css"
const GlobalStyle = createGlobalStyle`
  ${animate}

  ::selection {
    background-color: ${props => props.theme.colors.primary};
    color: #fff;
  }

  html {
    scroll-behavior: smooth;
    overflow-x: hidden;
  }

  body {
    color: #fff;
    overflow-x: hidden;
  }
  
  h2 {
    font-weight: bold;
    font-size: 1.8rem;
    text-transform: uppercase;
    margin-bottom: 50px;

    @media screen and (min-width: 600px){
      font-size: 2rem;
    }

    @media screen and (min-width: 900px){
      font-size: 2.5rem;
    }
  }
  p {
    font-size: 1.2rem;
  }

@media only screen and (max-width: 599px){
  .fix {
    ::after {
      content: "";
      display: block;
      position: absolute;
      height: 3px;
      width: 70%;
      background-color: ${props => props.theme.colors.primary};
      bottom: -10px;
      left: -15px;
    }
  }
} 
`

export default GlobalStyle
