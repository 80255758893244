import styled from "styled-components";

const TitleSection = styled.h2`
  display: block;
  position: relative;
  grid-column: 2;
  color: ${props => (props.dark ? "#fff" : props.theme.colors.title.dark)};
  width: max-content;

  ::after {
    content: "";
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: ${props => props.theme.colors.primary};
    bottom: -10px;
  }
`;

export default TitleSection;
